import styled from "styled-components";

interface ContainerProps {
    width?: number;
    flex?: boolean; //Default is false, when true, the flex properties will be applied 
    flexShrink?: number;
    flexGrow?: number;
    flexBasis?: string;
    aglinItems?: 'center' | 'flex-start' | 'flex-end';
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px;
    border-right: 1px solid #f4f5f7;

    ${props => props.flex && `
        flex: 1;
    `}

    ${props => props.width && `
        width: ${props.width}px;
        flex: 0 0 ${props.width}px;
    `}

    ${props => props.flexShrink && `
        flex-shrink: ${props.flexShrink};
    `}

    ${props => props.flexGrow && `
        flex-grow: ${props.flexGrow};
    `}

    ${props => props.flexBasis && `
        flex-basis: ${props.flexBasis};
    `}

    ${props => props.aglinItems && `
        justify-content: ${props.aglinItems};
    `}

`;
