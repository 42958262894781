import React from "react";

import {
    Container
} from "./style";

interface ListColumnProps {
    children?: React.ReactNode;
    width?: number;
    flex?: boolean; //Default is true, when true, the flex properties will be applied 
    flexShrink?: number;
    flexGrow?: number;
    flexBasis?: string; 
    aglinItems?: 'center' | 'flex-start' | 'flex-end';
}

const ListColumn: React.FC<ListColumnProps> = ({ children, width, flex = true, flexShrink, flexGrow, flexBasis, aglinItems }) => {

    return (
        <Container 
            width={width}
            flex={flex}
            flexShrink={flexShrink}
            flexGrow={flexGrow}
            flexBasis={flexBasis}
            aglinItems={aglinItems}
        >
            {children}
        </Container>
    );

}

export default ListColumn;