import styled from "styled-components";

interface ContainerProps {
    color?: string;
    fontSize?: string;
    fontWeight?: string;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.color};
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};

`;
