import React from "react";

import {
    Container
} from "./style";

interface ListItemProps {
    children?: React.ReactNode;
}

const ListItem: React.FC<ListItemProps> = ({ children }) => {

    return (
        <Container>
            {children}
        </Container>
    );

}

export default ListItem;