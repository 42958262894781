import React from "react";

import {
    Container,
    MainList
} from "./style";

interface ListProps {
    children?: React.ReactNode;
}

const List: React.FC<ListProps> = ({ children }) => {

    return (
        <Container>
            <MainList>
                {children}
            </MainList>
        </Container>
    );

}

export default List;