import React from 'react';
import { SubMenuContainer, LinkItem } from './style';

export interface NavigationBarProps {
    items: {
        label: string;
        id: number;
        segment: string;
        active?: boolean;
        badge?: string;
    }[];
    setNavBarSelected?: React.Dispatch<React.SetStateAction<number>>;
    navBarSelected?: number;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ items, setNavBarSelected, navBarSelected }) => {
    return (
        <SubMenuContainer>
            {items.map((item, index) => (
                <LinkItem
                    key={index}
                    className={item.id === navBarSelected ? 'active' : ''}
                    onClick={() => {
                        if (setNavBarSelected !== undefined) {
                            setNavBarSelected(item.id);
                        }
                    }}
                >
                    {item.label}
                    {item.badge && <span>{item.badge}</span>}
                </LinkItem>
            ))}            
        </SubMenuContainer>
    );
};

export default NavigationBar;