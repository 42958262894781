import styled from "styled-components";

interface ContainerProps {

}

export const Container = styled.div<ContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #f4f5f7;
`;
