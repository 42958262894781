import React from "react";

import {
    Container
} from "./style";

interface ListTextProps {
    children?: React.ReactNode;
    color?: string;
    fontSize?: string;
    fontWeight?: string;
}

const ListText: React.FC<ListTextProps> = ({ children, color = 'black', fontSize = '13px', fontWeight = '400' }) => {

    return (
        <Container 
            color={color}
            fontSize={fontSize}
            fontWeight={fontWeight}
        >
            {children}
        </Container>
    );

}

export default ListText;